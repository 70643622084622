@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

@import '../../declarations/scss.variables.scss';

*{
    font-family: 'Poppins', sans-serif;
}

#root {
    min-height: 100vh;
}

.ant-app {
    min-height: 100vh;
}

.ant-layout {
    min-height: 100vh;
    background: #fff;
    height: 0; //We need to put this because css is stupid and grand-childrens won't be able ot use height:100% without this

    > .ant-flex {
        min-height: 100vh;
    }
}

h1 {
    font-size: 2rem;
    line-height: 2rem;
}

a {
    color: $brand-color;
}

.ant-btn-primary {
    background: $brand-color;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        background: $brand-color-highlight;
    }

    &:not(:disabled):not(.ant-btn-disabled):active {
        background: $brand-color-highlight;
    }
}

.app-body {
    background: #f9fafc;
    width: auto;
    height: 100vh;
    overflow: auto;
    flex:1;
}