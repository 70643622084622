@import '../../../../declarations/scss.variables.scss';

.tokens-brief {
    border: solid 1px #ececec;
    border-radius: 1rem;
    padding: 1rem;

    .quantity {
        font-weight: bold;
    }

    .title {
        font-size: 0.95rem;
    }

    .subtitle {
        font-size: 0.95rem;
        color: #c0c0c0;
    }


    .ant-progress-inner {
        margin-top:0.5rem;
        background: #e6effe;

        .ant-progress-bg {
            height: 1rem !important;
            background: $brand-color;
        }
    }
}
