.history {
    margin-top: 2rem;
    width: 100%;

    .history-list-wrap {
        width: auto;
        padding: 0 1rem;
    }

    .history-list {
        gap: 1rem;
    }

    .history-card {
        border-radius: 6px;
        width: 180px;
        height: 320px;

        .thumbnail {
            width: 180px;
            height: 320px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ccc;

            &.error {
                background: black;
            }

            .ant-spin-sm {
                .ant-spin-dot {
                    font-size: 80px;
                }
            }
        }
    }
}
