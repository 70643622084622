@import '../../../declarations/scss.variables.scss';
$header-font-height: 1.1rem;


.app-menu {

	.computer-menu {
		background: #fff;
		height: 100vh;
		color: $font-color;
		font-size: $header-font-height;
		width: 20rem;
		max-width: 20rem;
		min-width: 20rem;
		margin: 0;
		padding: 0;
		gap: 2rem;
		padding-bottom:2rem;

		@media (max-width: 992px) {
			display: none;
		}

		a {
			color: $font-color;
		}

		.ant-menu {
			border-bottom: none;
		}

		.menu-area {
			.ant-menu-root {

				svg {
					color: $font-color;
				}

				@media (max-width: 992px) {
					display: none;
				}

				.ant-menu-item {
					padding-inline: 1rem;
					margin-inline: 1rem;
					width: calc(100% - 2rem);

					span {
						color: $font-color;
						font-size: $header-font-height;
					}

					&:hover {
						background: rgba($brand-color,0.1);
					}
				}
			}
		}

		.empty-area {
			flex: 1;
		}

		.language-area {
			padding-inline: 2rem;
		}

		.profile-area {
			padding-inline: 2rem;
		}

		.tokens-area {
			padding-inline: 2rem;
		}
	}

	.burger-menu {
		display: none;
		position: fixed;

		@media (max-width: 992px) {
			display: block;
		}
	}
}
