@import '../../declarations/scss.variables.scss';

$youtube-brand-color: #ff0000;
$youtube-font-color: #f1f1f1;
$youtube-background: #0f0f0f;

$instagram-brand-color: linear-gradient( to right, #833ab4,#fd1d1d,#fcb045 );
$instagram-font-color: #030303;

$tiktok-brand-color: #000;
$tiktok-font-color: #000;

.main-page {
    .ant-form {
        max-width: 50rem;
        width: 100%;
        padding: 0 1rem;

        .ant-form-item {
            width: 100%;
            text-align: center;
            input{
                max-width:100%,
            }
        }
    }

    .input-url {
        max-width: 50rem;
        width: 100%;
    }

    h1 {
        @media (max-width: 500px) {
            font-size: 1.7rem;
            line-height: 1.7rem;
        }

        @media (max-width: 450px) {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    .create-video-form {
        h1 {
            color: $brand-color;
        }

        .ant-btn-primary {
            -webkit-box-shadow: 0 5px 15px 5px rgba(34, 125, 199, .42);
            box-shadow: 0 5px 15px 5px rgba(34, 125, 199, .42);
            padding: 1rem 2rem;
            color: #fff;
            font-size: 1.1rem;
            font-weight: bold;
            height: 4rem;
            background: $brand-color;
            border: none;
        }
    }

    &.main-youtube {

        .create-video-form {
            h1 {
                color: $youtube-brand-color;
            }

            .ant-btn-primary {
                background: $youtube-brand-color;
                -webkit-box-shadow: 0 5px 15px 5px rgba($youtube-brand-color, .42);
                box-shadow: 0 5px 15px 5px rgba($youtube-brand-color, .42);
            }
        }
    }

    &.main-instagram {
        h1 {
            color: $instagram-font-color;
        }

        .create-video-form {
            .ant-btn-primary {
                background: $instagram-brand-color;
                -webkit-box-shadow: 0 5px 15px 5px rgba($youtube-brand-color, .42);
                box-shadow: 0 5px 15px 5px rgba($youtube-brand-color, .42);
            }
        }
    }

    &.main-tiktok {
        h1 {
            color: $tiktok-font-color;
            text-shadow: -1px 0 #25f4ee, 0 1px #fe2c55, 1px 0 #fe2c55, 0 -1px #25f4ee;
        }

        .create-video-form {
            .ant-btn-primary {
                background: $tiktok-brand-color;
                -webkit-box-shadow: 0 5px 15px 5px rgba($tiktok-brand-color, .42);
                box-shadow: 0 5px 15px 5px rgba($tiktok-brand-color, .42);
                border: solid 2px;
                border-image: linear-gradient(to right, #25f4ee, #fe2c55) 1;
            }
        }
    }

    .form-middle {
        .ant-form-item-control-input-content {
            width: auto;
        }
    }
}