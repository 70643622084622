@import '../../../../declarations/scss.variables.scss';

.logo {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $brand-color;
    padding: 1rem;
    width:100%;

    img {
        width: 3rem;
    }

    h1 {
        line-height: 1rem; //for align purposes has to be 1 instead of 1.1
        font-weight: bold;
        margin: 0;
        color: $brand-color;

        span {
            color: $brand-color;
        }
    }
}
