.project-chat {
    display: flex;
    flex-direction: column;
    height: 100%;

    .history {
        overflow: auto;

        .line {
            padding: 1rem;

            .dialog {

                .author {
                    font-weight: bold;
                    align-items: center;
                    padding: 2rem 0 1rem 0;
                    display: flex;
                    gap: 0.2rem;

                    .avatar {
                        img, svg {
                            width: 2rem;
                            height: auto;
                        }
                    }
                }

                .text {
                    padding-left: 2.2rem;
                }
            }

            .video-player {
                width: 360px;

                video {
                    width: 360px;
                    height: 640px;
                }
            }
        }
    }
}
