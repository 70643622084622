

.video-player {
    width: 360px;
    overflow:hidden;
    border-radius: 6px;

    video {
        width: 360px;
        height: 640px;
        display: block;
    }

    button {
        width: 100%;
        border-radius:0;
    }
}
