
@import '../../declarations/scss.variables.scss';

.layout-middle {
    padding: 8rem 0 6rem 0;
    height: 100%;

    h1, h2, h3, h4, h5, h6, h7, h8, p {
        text-align: center;
    }
}