
$form-size: 24rem;

.form-middle {
    max-width: $form-size;
    width: 100%;

    input {
        max-width: $form-size;
        width: 100%;
    }

    button {
        max-width: $form-size;
        width: 100%;
        height: 3.5rem;
        font-size: 1.1rem;
    }

    .ant-form-item {
        width: 100%;
        text-align: center;
    }

    .ant-form-item-control-input-content {
        width: 100%;

        *:last-child {
            margin-bottom: 0;
        }
    }

    .error {
        color: red;
    }
}
