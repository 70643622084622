@import  '../../declarations/scss.variables.scss';

.subscription-card {
    width: 300px;

    .ant-card-head-title {
        text-align: center;
    }

    .price {
        text-align: center;
        font-size: 2.2rem;
    }

    .button-line {
        height: 2.5rem;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            width: 100%;
        }

        button {
            width: 100%;
            height: 2.5rem;
        }
    }

    .line {
        font-size: 1rem;

        &:not(.disabled) {
            svg {
                color: $brand-color;
            }
        }

        &.disabled {
            color: #ccc;
        }
    }

    &:not(.subscription-card-0) {
        border-color: $brand-color;

        .ant-card-head {
            background: $brand-color;
            color: #fff;
        }
    }
}
