.gateway {
    .currencies {
        margin-bottom: 1rem;
    }

    .subscriptions {
        gap: 1rem;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    .options {
        gap: 1rem;
    }
}
